import { Injectable } from '@angular/core';
import { AnalyticsArguments, AnalyticsService } from '@seco/core';


/**
 * A dummy implementation of the AnalyticsService interface.
 * This service does not perform any actual tracking.
 */
@Injectable({
  providedIn: 'root'
})
export class DummyAnalyticsService implements AnalyticsService {
  track(_args: AnalyticsArguments): void {}

  stopTimer(_action: string, _actionValue?: string): void {}
}
