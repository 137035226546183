import { Logger } from "./logger";

export class URLUtils {
  private static readonly INTRANET_URL = 'intranet';

  private static readonly urlQuerySeparator = /\?(.*)/;
  private static readonly splitLimit = 2;

  /**
   * Retrieves the value of a specified query parameter from a given URL.
   *
   * @param url - The URL string from which to extract the query parameter value.
   * @param paramName - The name of the query parameter whose value is to be retrieved.
   * @returns The value of the specified query parameter, or `undefined` if the parameter is not found.
   */
  public static getQueryParamValue(url: string, paramName: string): string | undefined {
    const query = url.split(URLUtils.urlQuerySeparator, URLUtils.splitLimit)[1];

    return new URLSearchParams(query).get(paramName) ?? undefined;
  }

  /**
   * Determines if the current window is running in an iframe.
   * @returns {boolean} True if the window is embedded in an iframe, false otherwise.
   */
  public static isIframeMode() {
    return window.top !== window.self;
  }

  /**
   * Check if the context url is call from intranet or internet usage
   *
   * @returns True or False
   */
  public static isIntranet(): boolean {
    const url = window.location !== window.parent.location ? document.referrer : document.location.href;

    return url.indexOf(this.INTRANET_URL) >= 0;
  }

  /**
   * Parse the CLP Auth URL provided by the reef-gateway
   *
   * The CLP Auth url is configured with internet CLP login url, in case of intranet usage
   * we need to add 1a to the URL as workaround.
   *
   * @param clpAuthUrl the clp authorization url to parse
   * @returns clpAuthUrl adapted for intranet or internet usage
   */
  public static parseClpAuthUrl(clpAuthUrl: string): string {
    Logger.debug('[LoginService] parsing CLP Auth URL:', clpAuthUrl);
    return this.isIntranet()
      ? clpAuthUrl.replace(/(dev|pdt|mig|uat|skl|btprd|www)/i, '1a.$1').replace('www.', '')
      : clpAuthUrl;
  }

  /**
   * Extracts and normalizes the environment from a CLP authentication URL.
   *
   * @param clpAuthURL - The CLP authentication URL to parse (e.g., "https://1a.pdt.accounts.amadeus.com")
   * @returns The normalized environment name. Returns 'prd' for www or if no match is found.
   *
   * @example
   * ```
   * parseClpAuthEnvironement("https://1a.pdt.accounts.amadeus.com") // returns "pdt"
   * parseClpAuthEnvironement("https://www.accounts.amadeus.com")     // returns "prd"
   * ```
   */
  public static parseClpAuthEnvironement(clpAuthURL: string): string {
    const match = clpAuthURL.match(/https:\/\/((?:1a\.)?([^.]+))\.accounts\.amadeus\.com/);
    return match ? match[2].replace('www', 'prd') : 'prd';
  }
}
