import { Injectable } from '@angular/core';
import { ConfigurationForSpecificCustomerAndMarket, CustomerConfigurationService } from '@seco/insurance';
import { AIRP_CUSTOMER_CONFIG } from '../../configuration/customer-configuration/customer-configuration';
import { AIRP_CUSTOMER_CONFIG_DUMMY } from '../../configuration/customer-configuration/customer-configuration.tst';
import { Logger } from '../../core/util/logger';

@Injectable()
export class AirpCustomerConfigurationService extends CustomerConfigurationService {
  constructor() {
    super();

    this.environments = {
      dev: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      pdt: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      acc: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      uat: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      skl: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      prd: AIRP_CUSTOMER_CONFIG
    };
  }

  /**
   * Retrieves the configuration for a specific customer and market.
   * Override the super method to ensure lower case usage for environement in order
   * to match with the environement map.
   *
   * @param customerId - The unique identifier of the customer.
   * @param market - The market for which the configuration is being retrieved.
   * @param environment - The environment (e.g., production, staging) for which the configuration is being retrieved.
   * @returns The configuration for the specified customer and market.
   */
  public retrieveConfigurationByCustomerAndMarket(
    customerId: string,
    market: string,
    environment: string
  ): ConfigurationForSpecificCustomerAndMarket {
    Logger.debug('Retrieving configuration for customer and market', customerId, market, environment);
    return super.retrieveConfigurationByCustomerAndMarket(customerId, market, environment.toLowerCase());
  }
}
