import { AirpCustomerParameters } from '../../model/airp-customer-parameters';
import { Logger } from './logger';
import { SessionStorageUtils } from './session-storage';

/**
 * Post Message Helper used to send/receive post message to/from host application
 */
export class PostMessageHelper {
  static readonly INSURANCE_IFRAME_APP_NAME: string = 'INSURANCE_IFRAME';
  static readonly ARD_WEB_APP_NAME: string = 'ARD_WEB';
  static readonly REQUESTING_INIT_MESSAGE_NAME: string = 'requestingInit';
  static readonly SESSION_ID_MESSAGE_NAME: string = 'sessionId';
  static readonly INIT_MESSAGE_NAME: string = 'init';
  static isInitMessageReceivedFromHost: boolean = false;

  /**
   * Send requestingInit message to Host Application
   */
  static requestInitMessage() {
    this.postMessage(PostMessageHelper.REQUESTING_INIT_MESSAGE_NAME);
  }

  /**
   * Receive Init Message from Host Application
   * @param event the message received
   */
  static receiveInitMessage(event) {
    try {
      if (
        event.data?.header?.app === PostMessageHelper.ARD_WEB_APP_NAME &&
        event.data?.header?.name === PostMessageHelper.INIT_MESSAGE_NAME
      ) {
        this.isInitMessageReceivedFromHost = true;
        let customerCode: string = event.data.payload?.officeId?.substring(3, 5);
        customerCode = customerCode === undefined || customerCode === '1A' ? '6X' : customerCode;
        const airpCustomerParameters: AirpCustomerParameters = Object.assign(
          {},
          SessionStorageUtils.getCustomerInputs(),
          {
            customerCode,
            pnrRecLoc: event.data?.payload?.locator,
            officeId: event.data?.payload?.officeId,
            currencyCode: event.data?.payload?.currency,
            env: event.data?.payload?.backendPhase?.toLowerCase(),
            clpHost: event.data?.payload?.clpHost
          }
        );
        SessionStorageUtils.saveCustomerInputs(airpCustomerParameters);
      }
    } catch (error) {
      Logger.error(`Receive postMessage error ${JSON.stringify(error)}`);
    }
  }

  /**
   * Send sessionId message to Host Application
   * @param sessionId the sessionId to be shared with Host Application
   */
  static sendSessionIdMessage(sessionId: string) {
    this.postMessage(PostMessageHelper.SESSION_ID_MESSAGE_NAME, { sessionId });
  }

  /**
   * Sends a message to the parent window using the postMessage API.
   *
   * @param name - The name of the message.
   * @param payload - Optional payload of the message.
   */
  static postMessage(name: string, payload?: any) {
    const postMessage = {
      header: {
        app: PostMessageHelper.INSURANCE_IFRAME_APP_NAME,
        name: name,
        apiVersion: 1.0
      },
      payload
    };

    const targetOrigin = document.referrer ? new URL(document.referrer).origin : '*';
    window.parent.postMessage(postMessage, targetOrigin);

  }
}
