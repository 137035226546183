import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpConfigService, ReefAuthToken, ReefGatewayService, ReefLoginAccessTokenOutput } from '@seco/core';
import { ClpInitConfig, ClpLoginResponse, ClpService, LoginConfigService } from '@seco/login';
import { UpdateClpConfig, UserLoggedIn, UserSessionExpired } from '../store/core/actions';

import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { distinctUntilChanged, Observable, of, Subscription, take, tap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { Logger } from '../core/util/logger';
import { SessionStorageUtils } from '../core/util/session-storage';
import { URLUtils } from '../core/util/url-utils';
import { selectClpConfig } from '../store/core/selector';
import { AirpCoreState } from '../store/core/state';
import { LoggedInUser } from './model/logged-in-user';

/**
 * Service responsible for handling user authentication and login-related operations.
 * Manages user sessions, CLP (Client Login Platform) configuration, and gateway authentication.
 *
 * This service provides functionality for:
 * - Initializing and managing CLP configuration
 * - Handling user login through access tokens
 * - Managing user sessions and authentication state
 * - Processing logout operations
 * - Interacting with the Reef Gateway service
 *
 * @implements {OnDestroy} Angular lifecycle hook for cleanup
 */
@Injectable()
export class LoginService implements OnDestroy {
  private subscription: Subscription;

  constructor(
    // NOSONAR
    private readonly zone: NgZone,
    private readonly store: Store<AirpCoreState>,
    private readonly httpConfigService: HttpConfigService,
    private readonly router: Router,
    private readonly gwService: ReefGatewayService,
    private readonly clpService: ClpService,
    private readonly loginConfigService: LoginConfigService
  ) {

    this.loginConfigService.setConfig({
      clpAppId: 'INS',
      language: 'en_gb',
      clpConfig: this.store
        .select(selectClpConfig)
        .pipe(filter((config): config is ClpInitConfig => config !== undefined)),
      useSSO: URLUtils.isIframeMode() ? true : false,
      onSuccess: this.loginByAccessToken.bind(this)
    });
  }

  ngOnDestroy() {
    Logger.debug('[LoginService] ngOnDestroy');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Get token and nonce by calling UM action.
   */
  getClpConfig(): Observable<any> {
    return this.gwService.getAuthenticationToken().pipe(
      tap((res: ReefAuthToken) => {
        // Refresh the environment in case the OCTX requested was defaulted by the application configuration.
        const clpAuthEnvironment = URLUtils.parseClpAuthEnvironement(res.clpAuthURL);
        if (clpAuthEnvironment !== this.httpConfigService.config?.environment?.toLowerCase()) {
            Logger.debug(
            `[LoginService] The OCTX environment ${this.httpConfigService.config.environment} ` +
            `has been replaced by clp environement ${clpAuthEnvironment}`
            );
          this.httpConfigService.config.environment = clpAuthEnvironment;
        }

        const clipInitConfig: ClpInitConfig = {
          token: res.configToken,
          nonce: res.nonce,
          baseUrl: this.getClpHost(res.clpAuthURL),
          loginParameters: {
            officeNeeded: true,
            displayDutyCode: false
          }
        };

        this.initClpConfig();
        this.store.dispatch(new UpdateClpConfig(clipInitConfig));
      })
    );
  }

  /**
   * Retrieves the CLP host from the session storage or parses it from the CLP auth URL.
   * @param clpAuthURL - The CLP auth URL.
   * @returns The CLP host.
   */
  getClpHost(clpAuthURL) {
    return SessionStorageUtils.getCustomerInputs()?.clpHost || URLUtils.parseClpAuthUrl(clpAuthURL);
  }

  /**
   * Log user from the session storage / cookies
   */
  loginBySession(): boolean {
    Logger.debug(`[LoginService] trying to log from session`);
    if (this.isConnected()) {
      const user: LoggedInUser = this.getLoggedUser();

      Logger.debug(`[LoginService] User ${JSON.stringify(user)} is still valid`);

      const decodedIdToken = jwt_decode(user.idToken);

      this.loginByAccessToken({
        accessToken: user.accessToken,
        idToken: user.idToken,
        nonce: decodedIdToken.nonce
      });

      return true;
    }

    return false;
  }

  /**
   * Login by access token
   */
  loginByAccessToken(clpLoginResponse: ClpLoginResponse) {
    Logger.debug('[LoginService] loginByAccessToken with clp', clpLoginResponse);

    const loginData = {
      accessToken: clpLoginResponse.accessToken,
      idToken: clpLoginResponse.idToken,
      nonce: clpLoginResponse.nonce
    };

    // transmit the access token got from CLP from the authentication to the reef gateway to be used later on by the reef connect
    this.subscription = this.gwService.loginInByAccessToken(loginData).subscribe((res: ReefLoginAccessTokenOutput) => {
      // have to call inside zonejs to trigger change detection, CLP code is run by default outside zonejs
      this.zone.run(() => {
        this.httpConfigService.config.jSessionId = res.JsessionId;

        this.store.dispatch(
          new UserLoggedIn({
            userAlias: res.USER_ALIAS,
            userId: res.USER_ID as string,
            officeId: res.LOGIN_AREA_ID as string,
            organization: res.ORGANIZATION as string
          })
        );
      });
    });
  }

  /**
   * Check if the user is connected and token has not expired
   * @returns True if user is connected and token is still valid.
   */
  isConnected(): boolean {
    const user = this.getLoggedUser();

    return !!user && moment().isBefore(moment(user.expirationTime));
  }

  /**
   * Retrieves the currently logged-in user from the session storage.
   * @returns {LoggedInUser} The logged-in user object stored in session storage.
   */
  getLoggedUser(): LoggedInUser {
    return SessionStorageUtils.getClpUser();
  }

  /**
   * Logs out the current user from both CLP service and gateway.
   * Redirects to the current router URL after CLP logout.
   * @memberof LoginService
   */
  logOutUser() {
    Logger.debug('[LoginService] logOutUser');
    if (this.isConnected()) {
      this.clpService.logout(JSON.stringify({ postLogoutRedirectUri: this.router.url }), this.clpLogoutSuccess);
      this.gatewayLogout();
    } else {
      Logger.debug('[LoginService] Loggout aborted: User is not connected');
    }
  }

  /**
   * Initiates logout process when permission check fails.
   * Triggers a CLP service logout with the current router URL as the post-logout redirect URI.
   *
   * @remarks
   * This method serializes the post-logout redirect URI into JSON before passing it to the CLP service.
   */
  permissionFailedLogout() {
    this.clpService.logout(JSON.stringify({ postLogoutRedirectUri: this.router.url }), this.clpLogoutSuccess);
  }

  /**
   * Initiates a logout operation through the gateway service.
   *
   * This method performs the following operations:
   * 1. Calls the gateway service's logout endpoint
   * 2. Handles potential errors by dispatching a UserSessionExpired action
   * 3. Reloads the window on successful logout
   * 4. Performs cleanup by unsubscribing from any existing subscription
   *
   * @throws {Error} Caught and handled internally through UserSessionExpired action
   */
  gatewayLogout(): void {
    Logger.debug('[LoginService] gatewayLogout');
    // Call to reef gateway
    this.gwService
      .logout()
      .pipe(
        catchError((error) => {
          Logger.error('[LoginService] gatewayLogout error', error);
          this.store.dispatch(new UserSessionExpired(error.message));
          return of(null);
        })
      )
      .subscribe({
        next: () => {
          this.zone.run(() => {
            Logger.debug('[LoginService] logout triggered successfully');
            window.location.reload();
          });
        },
        complete: () => {
          // Ensure cleanup
          if (this.subscription) {
            Logger.debug('[LoginService] cleanup subscription');
            this.subscription.unsubscribe();
          }
        }
      });
  }

  private clpLogoutSuccess(): void {
    Logger.debug('[LoginService] CLP logout success');
  }

  /**
   * Initializes the CLP (Client Login Protocol) configuration by retrieving login configuration
   * and subsequent CLP-specific settings.
   *
   * The method performs the following steps:
   * 1. Retrieves the login configuration using loginConfigService
   * 2. Filters and processes the login configuration
   * 3. Subscribes to CLP configuration updates
   * 4. Initializes CLP service with the received configurations
   *
   * The observable chains use filtering, distinctUntilChanged, and take operators
   * to ensure proper configuration handling and prevent duplicate processing.
   *
   * @private
   * @returns {void}
   */
  private initClpConfig() {
    this.loginConfigService
      .getConfig()
      .pipe(
        filter((loginConfig) => !!loginConfig),
        distinctUntilChanged(),
        take(1)
      )
      .subscribe((loginConfig) => {
        loginConfig.clpConfig
          .pipe(
            filter((clpConfig) => !!clpConfig),
            distinctUntilChanged(),
            take(1)
          )
          .subscribe((clpConfig) => {
            Logger.debug('[LoginService] initClpConfig', clpConfig, loginConfig);
            this.clpService.initClpConfig(clpConfig, loginConfig);
          });
      });
  }
}
