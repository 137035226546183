import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, Params, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpConfigService } from '@seco/core';
import { Observable, of } from 'rxjs';
import { Logger } from '../../core/util/logger';
import { SessionStorageUtils } from '../../core/util/session-storage';
import { AirpCustomerParameters } from '../../model/airp-customer-parameters';
import { SetAppError } from '../../store/core/actions';
import { AirpCoreState } from '../../store/core/state';

/*
*  This class is used to extract and parse the query parameter
*/
@Injectable({
  providedIn: 'root'
})
export class ParameterGuardService implements CanActivate {

  constructor(
    private readonly httpConfigService: HttpConfigService,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store<AirpCoreState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    try {
      Logger.debug('[ParameterGuardService] canActivate query params verification');

      if (route.queryParams && Object.keys(route.queryParams).length) {
        const airpCustomerParameters = this.validateInputs(this.extractCustomerInput(route.queryParams));

        if (this.parameterArePresentAndFilled(airpCustomerParameters)) {
          Logger.debug(
            `[ParameterGuardService] All the query params are valid and have been saved in the session`,
            airpCustomerParameters
          );

          const airpCustomerParametersToSave: AirpCustomerParameters = Object.assign({},
            SessionStorageUtils.getCustomerInputs(),
            { ...airpCustomerParameters }
          );
          SessionStorageUtils.saveCustomerInputs(airpCustomerParametersToSave);
        }
      } else {
        Logger.debug('[ParameterGuardService] No query params to validate');
      }

      return of(true);
    } catch(error) {
      SessionStorageUtils.removeCustomerInputs();
      Logger.error('[ParameterGuardService] error with param validation => ', error);
      this.store.dispatch(new SetAppError(error));

      return of(false);
    }
  }

  /**
   * Check that the parameter are already present to avoid override by empty value
   * @param params the parameters extracted from the query params
   * @returns true or false
   */
  parameterArePresentAndFilled(params: AirpCustomerParameters): boolean {
    // TODO In case of SSO pnrRecLoc is not mandatory neither the officeId as we should be able to retrieve it
    const minExpectedKeys = ['customerCode'];

    return minExpectedKeys.every(key => params[key]?.length > 0);
  }

  /**
   * Extract the customer input parameters
   *
   * @param queryParams the query params to extract
   */
  extractCustomerInput(queryParams: Params): AirpCustomerParameters {
    const officeId = queryParams.OFFICE_ID || queryParams.OFFICE || queryParams.officeId || queryParams.office;
    let customerCode: string = officeId?.substring(3, 5);

    customerCode = (customerCode === undefined || customerCode === '1A') ? '6X' : customerCode;

    Logger.debug('[ParameterGuardService] extractCustomerInput queryParams', queryParams);

    return {
      customerCode:
        queryParams.CUSTOMER_CODE ||
        queryParams.CUSTOMER ||
        queryParams.customerCode ||
        queryParams.customer ||
        customerCode,
      pnrRecLoc: queryParams.REC_LOC || queryParams.recLoc || queryParams.pnrRecLoc || queryParams.locator,
      officeId,
      currencyCode:
        queryParams.CURRENCY || queryParams.CURRENCY_CODE || queryParams.currency || queryParams.currencyCode,
      languageCode:
        queryParams.LANGUAGE ||
        queryParams.LANG ||
        queryParams.LANGUAGE_CODE ||
        queryParams.language ||
        queryParams.lang ||
        queryParams.languageCode,
      siteCode: queryParams.SITE || queryParams.SITE_CODE || queryParams.siteCode,
      env: this.httpConfigService.getConfig()?.environment || 'prd',
      clpHost: queryParams.CLP_HOST
      //parentUrl: window.parent?.location?.href?.split('?')?.[0] || window.location.href.split('?')[0]
    };
  }

  /**
   * Validate and sanitize all propertys of a given AirpCustomersParameters
   *
   * @param params the query params set to sanitize
   */
  sanitizeInputs(params: AirpCustomerParameters): AirpCustomerParameters {
    const sanitizedParams = {...params};

    Object.keys(sanitizedParams).forEach(key => {
      sanitizedParams[key] = sanitizedParams[key]
        && encodeURIComponent(this.sanitizer.sanitize(SecurityContext.HTML, sanitizedParams[key]));
    });
    sanitizedParams.parentUrl = params.parentUrl; // we do not want to escape the parentUrl

    return sanitizedParams;
  }

  validateInputs(inputParams: AirpCustomerParameters): AirpCustomerParameters {
    const sanitizedInputParams = this.sanitizeInputs(inputParams);

    Logger.debug('[ParameterGuardService] validateInputs inputs param', inputParams,
      'vs sanitizedInputParams', sanitizedInputParams);

    this._validateParameterLengths(sanitizedInputParams);
    this._validateAlphanumeric(sanitizedInputParams);

    return sanitizedInputParams;
  }

  /**
   * Validate the length of each allowed AirpCustomersParameters properties
   *
   * @param params the query params to validate
   * @returns true if valid else throw an exception
   */
  private _validateParameterLengths(params: AirpCustomerParameters): boolean {
    const expectedLengths = {
      officeId: 9,
      pnrRecLoc: 6,
      siteCode: 8,
      languageCode: 2,
      currencyCode: 3
    };
    const nonMatchingParam = Object.keys(expectedLengths).find(key => params[key] && expectedLengths[key] !== params[key].length);

    if(nonMatchingParam) {
      throw new Error(`One of the param [${nonMatchingParam}] is not matching the lenghts`);
    }

    return true;
  }

  /**
   * Validate the pattern of each allowed AirpCustomersParameters properties
   *
   * @param params the query params to validate
   * @returns true if valid else throw an exception
   */
  private _validateAlphanumeric(params: AirpCustomerParameters): boolean {
    const nonMatchingParam = Object.keys(params).filter(key => key !== 'parentUrl')
    .filter(key => key !== 'clpHost').find( key => params[key] && !params[key].match('^[A-Za-z0-9]+$'));

    if (nonMatchingParam) {
      throw new Error(`One of the param [${nonMatchingParam}] is not alphanumeric`);
    }

    return true;
  }
}
