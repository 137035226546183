import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpConfigService } from '@seco/core';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { Logger } from '../../core/util/logger';
import { SessionStorageUtils } from '../../core/util/session-storage';
import { selectClpConfig } from '../../store/core/selector';
import { AirpCoreState } from '../../store/core/state';
import { LoginService } from '../login.service';
import { LoggedInUser } from '../model';

/*
 *  This class is used to check whether the user accessing this application under the configured route has a valid
 *  access token. Only with a valid access token a forwarding to the desired application is executed. The parameters
 *  sent by the host application are stored in a session storage for further use within the application.
 */
@Injectable({
  providedIn: 'root'
})
export class AccessGuardService implements CanActivate, OnDestroy {
  private subscription: Subscription;

  /**
   * AccessGuardService constructor.
   * @param store - The store service.
   * @param loginService - The login service.
   * @param httpConfigService - The HTTP configuration service used to fetch the environment.
   */
  constructor(
    private readonly store: Store<AirpCoreState>,
    private readonly loginService: LoginService,
    private readonly httpConfigService: HttpConfigService
  ) {}

  ngOnDestroy() {
    console.debug('[AccessGuardService] ngOnDestroy');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Determines if a route can be activated.
   *
   * @param route - The activated route snapshot.
   * @returns An observable that emits a boolean or UrlTree indicating if the route can be activated.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    //check if the officeId in the request is different than the one in the storage
    if (SessionStorageUtils.getClpUser() !== null && this.hasDifferentOfficeId(route)) {
      SessionStorageUtils.removeClpUser();
    }

    // Scenario 1 : do the authentication with token . Case after CLP redirect
    if (route.fragment && route.fragment.includes('access_token')) {
      Logger.debug(
        '[AccessGuardService] access_token is present in the url trying to login with it.'
      );
      this.loginWithAccessToken(route);

      return of(true);
    }

    // Scenario 2 : if the user is already found in the session reuse it. In case the page is reloaded.
    if (this.loginService.loginBySession()) {
      Logger.debug('[AccessGuardService] A CLP user was found in the session, reusing it. User: ',
        SessionStorageUtils.getClpUser());
      return of(true);
    }

    // Scenario 3 : trigger an authentication when no access token. Done at the first load and when no access token available
    this.subscription = this.store.select(selectClpConfig).subscribe((config) => {
      if (config?.baseUrl && SessionStorageUtils.getCustomerInputs()) {
        this.goToCLP(config.baseUrl, config.nonce);
      }
    });

    return of(false);
  }

  /**
   * Checks if the requested office ID is different from the user's office ID in the session storage.
   * @param route - The activated route snapshot.
   * @returns A boolean indicating whether the requested office ID is different from the user's office ID in the session storage.
   */
  hasDifferentOfficeId(route: ActivatedRouteSnapshot): boolean {
    const requestOfficeId = this.getRequestOfficeId(route);
    const user = SessionStorageUtils.getClpUser();
    return requestOfficeId && requestOfficeId !== user?.officeId;
  }

  /**
   * Retrieves the office ID from the provided route.
   * The office ID is obtained from post parameters, query parameters, or the access token.
   *
   * @param route - The ActivatedRouteSnapshot containing the route information.
   * @returns The office ID as a string, or an empty string if not found.
   */
  getRequestOfficeId(route: ActivatedRouteSnapshot): string {
    //check the post params
    const postParametersCustomerInput = SessionStorageUtils.getCustomerInputs();
    if (postParametersCustomerInput && postParametersCustomerInput.officeId) {
      return postParametersCustomerInput.officeId;
    }
    //check the query params [DEPRECATED]. To be removed once ARDWeb migrate to post params
    const queryParams = route.queryParams;
    if (queryParams && queryParams['officeId']) {
      return queryParams['officeId'];
    }
    //check the token. Used after the redirect from CLP for authentication
    const clpFragment: LoggedInUser = this.extractAccessToken(route);
    if (clpFragment && clpFragment.officeId) {
      return clpFragment.officeId;
    }
    return '';
  }

  /**
   * Extracts the access token and other relevant information from the provided ActivatedRouteSnapshot.
   * @param route - The ActivatedRouteSnapshot containing the fragment with the access token.
   * @returns The LoggedInUser object containing the extracted access token and other information.
   */
  extractAccessToken(route: ActivatedRouteSnapshot): LoggedInUser {
    let idToken;
    let accessToken;
    let expiresIn;

    new URLSearchParams(route.fragment).forEach((value, key) => {
      if (key === 'id_token') {
        idToken = value;
      }
      if (key === 'access_token') {
        accessToken = value;
      }
      if (key === 'expires_in') {
        expiresIn = value;
      }
    });

    const decodedIdToken = jwt_decode(idToken);
    const clpFragment: LoggedInUser = {
      userAlias: decodedIdToken['login'],
      organization: decodedIdToken['organization'],
      nonce: decodedIdToken['nonce'],
      officeId: decodedIdToken['office'],
      accessToken,
      idToken,
      expirationTime: expiresIn
    };

    new URLSearchParams(route.fragment).forEach((value, key) => (clpFragment[key] = value));

    clpFragment.expirationTime = moment().add(expiresIn, 'seconds').format();

    return clpFragment;
  }

  /**
   * Logs in the user using the access token from the provided route.
   * @param route - The activated route snapshot containing the access token.
   */
  loginWithAccessToken(route: ActivatedRouteSnapshot) {
    let expiresIn;

    new URLSearchParams(route.fragment).forEach((value, key) => {
      if (key === 'expires_in') {
        expiresIn = value;
      }
    });

    const clpFragment: LoggedInUser = this.extractAccessToken(route);
    clpFragment.expirationTime = moment().add(expiresIn, 'seconds').format();

    this.loginService.loginByAccessToken({
      accessToken: clpFragment['access_token'],
      idToken: clpFragment['id_token'],
      nonce: clpFragment['nonce']
    });

    SessionStorageUtils.saveClpUser(clpFragment);
  }

  /**
   * Redirects the user to the CLP (Central Login Page) with the specified base URL and nonce.
   * @param baseUrl - The base URL of the CLP.
   * @param nonce - The nonce value used for authentication.
   */
  goToCLP(baseUrl: string, nonce: string) {
    document.location.href = `${baseUrl}/LoginService/authorizeAngular?${this.constructQueryParamsForLogin(nonce)}`;
  }

  /**
   * Checks if the application is running in an iframe.
   * @returns {boolean} True if the application is running in an iframe, false otherwise.
   */
  isIframeMode(): boolean {
    return window.top !== window.self;
  }

  /**
   * Constructs query parameters for login.
   * @param nonce - The nonce value.
   * @returns A string representing the constructed query parameters.
   */
  constructQueryParamsForLogin(nonce: string): string {
    const paramsToAdd = [
      { key: 'service', value: 'INS' },
      {
        key: 'client_id',
        value: this.parseClientId()
      },
      { key: 'prompt', value: this.isIframeMode() ? 'none' : 'login' },
      { key: 'redirect_uri', value: window.location.href.split('?')[0] },
      { key: 'x-frame-request', value: document.referrer.split('?')[0] },
      { key: 'office', value: SessionStorageUtils.getCustomerInputs()?.officeId },
      { key: 'response_mode', value: 'fragment' },
      { key: 'nonce', value: nonce }
    ];

    let parameters = new HttpParams();

    paramsToAdd
      .filter((param) => !!param.value)
      .forEach((param) => (parameters = parameters.append(param.key, param.value)));

    return parameters.toString();
  }

  /**
   * Determines and returns the client identifier based on the environment configuration.
   *
   * @returns {string} The client identifier:
   *                   - 'AIRP' for production or pdt environment
   *                   - 'AIRPU' for UAT environment
   *                   - 'AIRPK' for SKL environment
   */
  parseClientId(): string {
    const environment = this.httpConfigService.getConfig()?.environment?.toLowerCase();
    let clientId = 'AIRP';
    if (environment === 'uat') {
      clientId = 'AIRPU';
    } else if (environment === 'skl') {
      clientId = 'AIRPK';
    }

    console.debug(`[AccessGuardService] Client ID ${clientId} for environment ${environment}`);
    return clientId;
  }
}
