export enum LogLevel {
  DISABLED = 0,
  ERROR = 1,
  WARNING = 2,
  INFO = 3,
  DEBUG = 4,
}

export class Logger {
  static LOG_LEVEL: LogLevel = LogLevel.WARNING;

  static isLogEnabled(logLevel: LogLevel): boolean {
    return LogLevel[Logger.LOG_LEVEL] !== undefined && logLevel <= Logger.LOG_LEVEL;
  }

  /**
   * Log error message only if log level allow it
   *
   * @param error the error message to log
   */
  static error(error: any, ...optionalParams: any[]): void {
    Logger.log(LogLevel.ERROR, error, optionalParams);
  }

  /**
   * Log warning message only if log level allow it
   *
   * @param warning the warning message to log
   */
  static warning(warning: any, ...optionalParams: any[]): void {
    Logger.log(LogLevel.WARNING, warning, optionalParams);
  }

  /**
   * Log info message only if log level allow it
   *
   * @param info the info message to log
   */
  static info(info: any, ...optionalParams: any[]): void {
    Logger.log(LogLevel.INFO, info, optionalParams);
  }

  /**
   * Log debug message only if log level allow it
   *
   * @param debug the debug message to log
   */
  static debug(debug: any, ...optionalParams: any[]): void {
    Logger.log(LogLevel.DEBUG, debug, optionalParams);
  }

  static log(logLevel: LogLevel, msg: any, ...optionalParams: any[]): void {
    if (this.isLogEnabled(logLevel)) {
      switch (logLevel) {
        case LogLevel.ERROR:
            console.error(msg, ...optionalParams);
          break;
        case LogLevel.WARNING:
          console.warn(msg, ...optionalParams);
          break;
        case LogLevel.INFO:
          console.info(msg, ...optionalParams);
          break;
        case LogLevel.DEBUG:
          console.debug(msg, ...optionalParams);
          break;
      }
    }
  }
}
